import { Routes } from '@angular/router';
import { RouteGuards } from '@ic/pwp-ui';
export const appRoutes: Routes = [
  {
   path: 'prfadvisorwfapp',
    canActivate: [RouteGuards],
    resolve: { config: RouteGuards },
    data: { workflow: 'PRFAdvisor.WF.App' },
    loadChildren: () => import('./jig/PRFAdvisor.WF.App/PRFAdvisor.WF.App.module').then(m => m.PRFAdvisorWFAppModule)
  },
  {
   path: 'rpbreportbundlerwfapp',
    canActivate: [RouteGuards],
    resolve: { config: RouteGuards },
    data: { workflow: 'RPBReportBundler.WF.App' },
    loadChildren: () => import('./jig/RPBReportBundler.WF.App/RPBReportBundler.WF.App.module').then(m => m.RPBReportBundlerWFAppModule)
  },
  {
   path: 'cpselfsignupwfapp',
    canActivate: [RouteGuards],
    resolve: { config: RouteGuards },
    data: { workflow: 'CPSelfSignUp.WF.App' },
    loadChildren: () => import('./jig/CPSelfSignUp.WF.App/CPSelfSignUp.WF.App.module').then(m => m.CPSelfSignUpWFAppModule)
  },
  {
   path: 'icxloginwfapp',
    canActivate: [RouteGuards],
    resolve: { config: RouteGuards },
    data: { workflow: 'ICXLogin.WF.App' },
    loadChildren: () => import('./jig/ICXLogin.WF.App/ICXLogin.WF.App.module').then(m => m.ICXLoginWFAppModule)
  },
  {
   path: 'whiteinterstitialwfapp',
    canActivate: [RouteGuards],
    resolve: { config: RouteGuards },
    data: { workflow: 'WhiteInterstitial.WF.App' },
    loadChildren: () => import('./jig/WhiteInterstitial.WF.App/WhiteInterstitial.WF.App.module').then(m => m.WhiteInterstitialWFAppModule)
  },
  {
   path: 'icnewclientwfapp',
    canActivate: [RouteGuards],
    resolve: { config: RouteGuards },
    data: { workflow: 'ICNewClient.WF.App' },
    loadChildren: () => import('./jig/ICNewClient.WF.App/ICNewClient.WF.App.module').then(m => m.ICNewClientWFAppModule)
  },
  {
   path: 'whiteloginwfapp',
    canActivate: [RouteGuards],
    resolve: { config: RouteGuards },
    data: { workflow: 'WhiteLogin.WF.App' },
    loadChildren: () => import('./jig/WhiteLogin.WF.App/WhiteLogin.WF.App.module').then(m => m.WhiteLoginWFAppModule)
  },
  {
   path: 'icadvisorwfapp',
    canActivate: [RouteGuards],
    resolve: { config: RouteGuards },
    data: { workflow: 'ICAdvisor.WF.App' },
    loadChildren: () => import('./jig/ICAdvisor.WF.App/ICAdvisor.WF.App.module').then(m => m.ICAdvisorWFAppModule)
  },
  {
   path: 'passwordresetwfapp',
    canActivate: [RouteGuards],
    resolve: { config: RouteGuards },
    data: { workflow: 'PasswordReset.WF.App' },
    loadChildren: () => import('./jig/PasswordReset.WF.App/PasswordReset.WF.App.module').then(m => m.PasswordResetWFAppModule)
  },
  {
   path: 'rncwfapp',
    canActivate: [RouteGuards],
    resolve: { config: RouteGuards },
    data: { workflow: 'RNC.WF.App' },
    loadChildren: () => import('./jig/RNC.WF.App/RNC.WF.App.module').then(m => m.RNCWFAppModule)
  },
  {
   path: 'dapmqmawfapp',
    canActivate: [RouteGuards],
    resolve: { config: RouteGuards },
    data: { workflow: 'DAP.MQMA.WF.App' },
    loadChildren: () => import('./jig/DAP.MQMA.WF.App/DAP.MQMA.WF.App.module').then(m => m.DAPMQMAWFAppModule)
  },
  {
   path: 'daprmqmawfapp',
    canActivate: [RouteGuards],
    resolve: { config: RouteGuards },
    data: { workflow: 'DAPR.MQMA.WF.App' },
    loadChildren: () => import('./jig/DAPR.MQMA.WF.App/DAPR.MQMA.WF.App.module').then(m => m.DAPRMQMAWFAppModule)
  },
  {
   path: 'daraccountopeningwfapp',
    canActivate: [RouteGuards],
    resolve: { config: RouteGuards },
    data: { workflow: 'DARAccountOpening.WF.App' },
    loadChildren: () => import('./jig/DARAccountOpening.WF.App/DARAccountOpening.WF.App.module').then(m => m.DARAccountOpeningWFAppModule)
  },
  {
   path: 'icclientwfapp',
    canActivate: [RouteGuards],
    resolve: { config: RouteGuards },
    data: { workflow: 'ICClient.WF.App' },
    loadChildren: () => import('./jig/ICClient.WF.App/ICClient.WF.App.module').then(m => m.ICClientWFAppModule)
  },
  {
   path: 'dapclientwfapp',
    canActivate: [RouteGuards],
    resolve: { config: RouteGuards },
    data: { workflow: 'DAPClient.WF.App' },
    loadChildren: () => import('./jig/DAPClient.WF.App/DAPClient.WF.App.module').then(m => m.DAPClientWFAppModule)
  },
  {
   pathMatch: 'full',
   path: 'IC10cAdvisor',
   loadChildren: () => import('./jig/IC10cAdvisor/IC10cAdvisor.module')
  },
  {
   pathMatch: 'full',
   path: 'IC10cClient',
   loadChildren: () => import('./jig/IC10cClient/IC10cClient.module')
  },
];
